@import '~crazy-ui/src/scss/common/vars';
@import '~crazy-ui/src/scss/common/mixins';
@import '~crazy-ui/src/scss/common/typography';

$line-height: 3.25rem;
$radius: 5px;

$font-size: 0.875rem;

.block {
  position: relative;
  padding: unset;

  &:last-child {
    margin-bottom: unset;
  }

  &:after {
    position: absolute;
    content: '';
    z-index: -1;
    background-color: white;
    border-radius: $radius;
    left: 10px;
    right: 10px;
    height: 100%;
    box-shadow: 0 3px 3px #e9e7e8;
  }

  &:hover {
    [data-small-control='true'] button {
      display: inline-flex;
    }
  }

  [data-small-control='true'] button {
    display: none;
  }
}

.logo > div {
  $size: 42px;
  width: $size;
  height: $size;
}

.userName {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: flex-start;

  @include text-body2;
  .name {
    width: 100%;
    font-weight: 600;
    word-break: break-all;
  }

  .email {
    width: 100%;
    color: $text-lighter;
    word-break: break-all;
  }
}
