@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 24px;
}

.label {
  display: flex;
  align-items: center;
  background: $cyan-100;
  border-radius: 8px;
  color: $cyan-500;
  padding: 4px 6px 4px 14px;
  font-size: 14px;

  button {
    $size: 20px;
    width: $size;
    height: $size;
    padding: 0;
    margin-left: 8px;
    cursor: pointer;
  }

  svg {
    width: auto;
    fill: $cyan-500;
    pointer-events: none;

    &:hover {
      fill: $cyan-700;
    }
  }
}
