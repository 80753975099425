@import 'node_modules/crazy-ui-next/src/scss/common/colors';

@import 'node_modules/crazy-ui-next/src/scss/common/typography';

.wrapper {
  color: $text-color;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    p {
      @include text-body1-medium;
      font-size: 24px;
      vertical-align: middle;
      color: $gray-800;
    }
  }

  .controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;

    button:first-child {
      margin-right: 16px;
    }
  }
}
