@import 'src/ui/scss/common/vars';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.fieldRow {
  display: flex;
  align-items: center;
  gap: 16px;

  .fieldInput {
    div {
      width: 500px;
      p {
        white-space: nowrap;
      }
    }
  }

  > svg {
    align-self: flex-start;
    margin-top: 36px;
    fill: $text-lighter;
    height: 16px;
    width: 16px;
  }
}

.recommendationGames {
  flex: 1;

  > div {
    display: flex;
    align-items: flex-end;
    gap: 16px;

    > div {
      width: 500px;
    }

    > svg {
      fill: $text-lighter;
      margin-bottom: 30px;
    }
  }
}

.emailRow {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    margin-bottom: 16px;

    > div {
      width: 500px;
      margin-bottom: unset;
    }

    > svg {
      fill: $text-lighter;
      margin-bottom: 12px;
    }
  }
}

.subtitle {
  font-size: 1.5rem;
  color: $text-color;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 22px;
}
