@import '~crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/colors';
@import '~crazy-ui-next/src/scss/common/shadows';

$region-section-width: 180px;
$status-section-width: 1fr;
$actions-section-width: 100px;

$border-radius: 10px;

.wrapper {
  grid-area: winner;
  background-color: white;
  padding: 20px 16px 20px 30px;
  box-shadow: $shadow-s;
  border-radius: 8px;
  overflow: hidden;
  flex: 1;
  min-height: 135px;
  max-height: 226px;
  //height: fit-content;

  h5 {
    @include text-body1;
    color: $gray-800;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .listWrapper {
    max-height: 160px;
    overflow: hidden scroll;
    padding-right: 4px;

    &::-webkit-scrollbar {
      height: 100%;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: $gray-500;
      border-radius: 10px;
    }
  }

  .shadow {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      background-image: linear-gradient(transparent 50%, white 100%);
      width: 100%;
      height: 35px;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }
  }

  ul {
    border-radius: $border-radius;
    overflow: hidden;
    border: 1px solid $gray-300;
    @include text-body2-medium;
    color: $gray-800;

    li {
      min-height: 48px;
      height: fit-content;
      display: grid;
      grid-template-columns: $region-section-width $status-section-width $actions-section-width;
      grid-template-rows: minmax(auto, 1fr) minmax(max-content, auto);
      grid-auto-flow: row;
      box-sizing: border-box;
      grid-template-areas: 'region state actions';

      .item {
        padding: 6px 16px;

        &:not(:last-child) {
          border-right: 1px solid $gray-300;
        }
      }

      &.applied {
        position: relative;

        &:before {
          position: absolute;
          content: '';
          width: 6px;
          height: 100%;
          background-color: $cyan-500;
        }
      }

      .region {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;

        svg {
          $size: 32px;
          height: $size;
          width: $size;
          fill: $cyan-500;
        }
      }

      .stateWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .state {
          color: $text-color;
          text-transform: uppercase;

          span {
            color: $gray-500;
            text-transform: none;
            font-weight: normal;
          }
        }

        .info {
          p {
            text-transform: none;
            @include text-body2;
            color: $gray-600;

            span {
              color: $cyan-500;
            }
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-area: actions;
        gap: 4px;

        button:hover {
          box-shadow: none;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $gray-300;
      }

      &.multiple {
        height: fit-content;

        .info {
          p {
            margin: 0;

            &:first-child {
              margin-top: 4px;
            }
          }

          .versionsRow {
            color: $text-color;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
