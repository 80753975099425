@import '~crazy-ui/src/scss/common/vars';

.controls {
  margin: 1rem 0 0 0;
  padding: 1.75rem 0;

  display: flex;
  justify-content: center;
  align-items: center;

  > button,
  a {
    margin-right: 1.5rem;
    min-width: 150px;

    &:last-child {
      margin-right: unset;
    }
  }
}

.withBorder {
  border-top: 1px solid $border-color;
}
