$neutral: white;
$neutral_5: darken($neutral, 5%);
$neutral_10: darken($neutral, 10%);
$neutral_20: darken($neutral, 20%);
$neutral_30: darken($neutral, 30%);
$neutral_40: darken($neutral, 40%);
$neutral_50: darken($neutral, 50%);
$neutral_60: darken($neutral, 60%);
$neutral_70: darken($neutral, 70%);
$neutral_80: darken($neutral, 80%);
$neutral_90: darken($neutral, 90%);
$neutral_95: darken($neutral, 95%);

$primary: #00a3c6;

$text-color: #2e3b3e;
$text-lighter: #829598;
$text-darker: darken($text-lighter, 10%);

$border-color: $neutral_10;
$border-color-input: #c9d8db;

$background-color: #f5f4ef;
$background-green: #f5fafb;
$background-blue: #dfebee;

$modal-bg-color: rgba(0, 0, 0, 0.3);

$tt: #99adb0;

$alert-red: #f35a4f;
$alert-green: #b0db43;
$alert-yellow: #e2b155;

$status-gray: #c9d8db;
$status-yellow: #e2b155;
$status-green: #b0db43;
$status-blue: #49acdb;
$status-red: #f35a4f;

$border-radius-default: 6px;

$text-light: 300;
$text-medium: 500;
$text-bold: 700;
$text-black: 900;

$z-index-black-overlay: 990;
$z-index-dropdown: 1000;
$z-index-sticky: 1020;
$z-index-fixed: 1030;
$z-index-modal-backdrop: 1040;
$z-index-modal: 1050;
$z-index-popover: 1060;
$z-index-tooltip: 1070;

$color-lime: #69be60;
$color-yellow: #ffaf5f;
$color-apricot: #f07e68;
$color-red: #ef6363;
$color-violet: #b96e9f;
$color-indigo: #8e97f1;
$color-blue: #4cb1e9;
$color-mint: #13be99;
