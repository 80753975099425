.input {
  max-width: 360px;
  margin-bottom: 24px;
}

.multiInputEl {
  margin-bottom: unset;

  div {
    width: 88px;
    margin-right: 12px;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;

  &.multiInputWrapper {
    margin-bottom: 24px;
  }
}

.targetConfig {
  max-width: 600px;
}

.compactDropdown {
  ul {
    max-height: 300px;
    gap: 16px;
    overflow: auto !important; //fixme move to crazy-ui-next(?)
  }
}
