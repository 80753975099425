@import '~crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/colors';
@import '~crazy-ui-next/src/scss/common/vars_old';

.info {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.title {
  @include text-body1-medium;
  color: $gray-800;
  text-align: left;
  margin-bottom: 16px;
}

.legend {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  margin-left: 32px;
  margin-bottom: 16px;
  @include text-caption;
  color: $gray-600;

  .item {
    display: flex;
    align-items: center;
  }
}

.symbol {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: $neutral_40;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 5px;
    left: 5px;
    background: white;
    opacity: 0.7;
  }
}
