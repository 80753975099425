@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';

$radius: 10px;

.wrapper {
  overflow-x: auto;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-500;
  }

  &:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid $gray-200;
    margin-bottom: 24px;
  }

  ul {
    margin-bottom: 32px;
    padding-left: 6px;
  }

  button {
    @include text-body1-medium;
    text-transform: uppercase;
    color: $cyan-500;
    margin-bottom: 32px;

    &:not(:disabled) {
      &:hover {
        cursor: pointer;
        color: $cyan-300;
      }
    }
  }
}

.table {
  @include text-body2;
  box-shadow: $shadow-xs;
  border-radius: $radius;
  overflow: hidden;
  color: $text-color;

  thead {
    @include text-caption;

    th {
      background: $gray-100;

      &:first-child {
        border-top-left-radius: $radius;
      }
      &:last-child {
        border-top-right-radius: $radius;
      }
    }
  }

  tbody {
    tr:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: $radius;
        }
        &:last-child {
          border-bottom-right-radius: $radius;
        }
      }
    }
  }

  th,
  td {
    padding: 1rem;
    border: 1px solid $gray-300;
  }

  p {
    text-align: center;
  }
}
