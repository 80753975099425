@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.table {
  $icon-col: 36px;
  $rec-col: 100px;
  $title-col: minmax(140px, 1fr);
  $store-col: 100px;
  $type-col: 150px;
  $name-col: minmax(140px, 2fr);
  $status-col: 200px;
  $date-col: 100px;
  $country-col: 100px;

  thead {
    th {
      &:first-child {
        grid-column: 1 / 3;
        text-align: left;
      }
    }
  }

  tr {
    grid-template-columns:
      $icon-col
      $title-col
      $name-col
      $store-col
      $type-col
      $status-col
      $date-col
      $country-col;
  }
}
