@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/typography';

.wrapper {
  display: flex;

  .inputs {
    display: flex;
    align-items: center;

    div {
      margin-bottom: 0;
    }

    &.errorInputs {
      div {
        border-color: $alert-error;

        input {
          color: $alert-error;
        }
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;

    button {
      min-width: unset;
      width: 32px;
      height: 32px;

      &.minus {
        fill: $magenta-500;
      }
    }
  }
}
