.table {
  display: flex;
  flex-direction: column;

  $border-color: $neutral_20;

  $row-height: 3rem;

  border: 2px solid $border-color;

  $bestResult: 2rem;

  .head {
    &.row {
      color: $text-lighter;
      font-weight: bold;
      font-size: 0.85rem;
      border-bottom: 2px solid $border-color;
      background-color: lighten($background-blue, 7%);

      .item[data-type='bestResult'] {
        width: $bestResult;
      }
    }
  }

  .row {
    display: flex;
    flex-grow: 1;

    border-bottom: 1px solid $border-color;

    justify-content: space-between;

    background-color: lighten($background-blue, 5%);

    &.best {
      background-color: lighten($color-lime, 35%) !important;
      &:hover {
        background-color: lighten($color-lime, 30%) !important;
      }
    }

    &:nth-child(2n) {
      background-color: lighten($background-blue, 10%);
    }

    &:last-child {
      border-bottom: unset;
    }

    &:hover {
      background-color: lighten($background-blue, 1%);
    }
  }

  .item {
    border-right: 1px solid $border-color;

    &:last-child {
      border-right: unset;
    }

    display: flex;

    word-break: break-word;

    width: calc(100% / 20);
    align-items: center;
    padding: 0.25rem 0.5rem;

    &.best-result {
      width: $bestResult;
      justify-content: center;
      align-content: center;
    }

    .dot {
      display: inline-flex;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background-color: $color-lime;
    }
  }
}
