@use 'sass:math';
@import '~crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/colors';
@import '~crazy-ui-next/src/scss/common/shadows';

$icon-wrapper-width: 62px;

@mixin toast-icon($url, $background-color, $border-color) {
  content: '';
  background-image: url($url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 42px;
  position: absolute;
  left: 0;
  top: 0;
  width: $icon-wrapper-width;
  height: 100%;
  background-color: $background-color;
  border-right: 1px solid $border-color;
}

@mixin toast-title($title) {
  content: $title;
  @include text-body2-medium;
  color: $gray-800;
  margin-bottom: 4px;
}

.Toastify__toast {
  display: flex;
  flex-direction: column-reverse;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  box-shadow: $shadow-s;
  background-color: #fff;
  padding: 16px 16px 16px calc(#{$icon-wrapper-width} + 16px);
  position: relative;
}
.Toastify__toast--error {
  border-color: $magenta-500;
}
.Toastify__toast--error::before {
  @include toast-icon('../../../ui/assets/svg/error.svg', $magenta-100, $magenta-500);
}
.Toastify__toast--error::after {
  @include toast-title('ERROR');
}

.Toastify__toast--success {
  border-color: $green-500;
}
.Toastify__toast--success::before {
  @include toast-icon('../../../ui/assets/svg/success.svg', $green-100, $green-500);
}
.Toastify__toast--success::after {
  @include toast-title('SUCCESS');
}

.Toastify__toast--info {
  border-color: $cyan-500;
}
.Toastify__toast--info::before {
  @include toast-icon('../../../ui/assets/svg/info.svg', $cyan-100, $cyan-500);
}
.Toastify__toast--info::after {
  @include toast-title('INFO');
}

.Toastify__toast--warning {
  border-color: $yellow-500;
}
.Toastify__toast--warning::before {
  @include toast-icon('../../../ui/assets/svg/warning.svg', $yellow-100, $yellow-500);
}
.Toastify__toast--warning::after {
  @include toast-title('WARNING');
}

.Toastify__toast-body {
  @include text-body2;
  color: $gray-800;
}
.Toastify__toast > button {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 18px;
  height: 18px;
  background-color: $gray-500;
  border-radius: 5px;
}
