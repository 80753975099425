@import '~crazy-ui/src/scss/common/vars';
@import '~crazy-ui/src/scss/common/mixins';
@import '~crazy-ui/src/scss/common/typography';

.rolesBlock {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.roleList {
  display: flex;
  flex-direction: column;
}

.roleItem {
  display: inline-flex;
  align-items: center;
  width: 100%;

  > label {
    margin: 0.25rem 0;
  }
}
