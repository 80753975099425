.experiment {
  &__item {
    //$line-height: 2rem;
    ////padding: unset;
    ////padding: 1rem 0.75rem;
    //padding: 0.75rem;
    //box-shadow: 0 3px 3px #e9e7e8;
    //background-color: white;
    //border-radius: 5px;
    ////display: flex;
    //margin-bottom: 10px;
    //
    //.link {
    //  color: inherit;
    //  text-decoration: none;
    //
    //  &:hover {
    //    color: black;
    //    text-decoration: underline;
    //  }
    //}
  }

  &__status-block {
    display: flex;
    align-items: center;
  }

  &__text-block {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .count {
      font-size: 0.85rem;
      margin-left: 0.5rem;
      color: $color-indigo;
      word-break: normal;
    }

    .experiment-name {
      display: inline-flex;
      align-items: center;
      font-size: 1.175rem;
      line-height: 1.5rem;
      color: $text-color;
      //word-break: break-word;
    }

    .firebase-name {
      font-size: 0.75rem;
      line-height: 1rem;
      color: $text-lighter;
    }

    .game-name {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: bold;
      color: $primary;
    }
  }

  &__time-block {
    $line-height: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    //margin-left: 1rem;
    //min-width: 125px;

    color: $text-color;

    svg {
      margin-right: 0.5rem;
      line-height: $line-height;
      height: $line-height;
      width: $line-height - 0.5rem;
      fill: $text-lighter;
    }

    .check-period {
      display: inline-flex;
      align-items: center;
      font-size: 1.125rem;
      line-height: $line-height;
    }

    .start-date {
      display: inline-flex;
      align-items: center;
      font-size: 1rem;
      line-height: $line-height;

      margin-top: 1rem;
    }
  }

  &__controls-block {
    display: flex;
    align-content: flex-start;
    align-items: center;
    //margin-left: auto;

    justify-content: flex-end;

    .c-btn {
      padding: 0.5rem;
      margin: 0 0.25rem;

      svg {
        width: 1.125rem;
        height: 1.125rem;
        transform: scale(1.25);
        margin-right: unset;
      }
    }
  }
}
