@import '~crazy-ui/src/scss/common/vars';
@import '~crazy-ui/src/scss/common/typography';

.group {
  display: inline-flex;
  align-items: center;
  //cursor: pointer;
}

.button {
  @include text-body2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $neutral_10;
  padding: 0.25rem 0.4rem;
  min-width: 80px;
  color: $neutral_60;
  user-select: none;
  cursor: pointer;

  $radius: 0.5rem;

  transition: all 100ms ease-in;

  &:first-child {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }

  &:last-child {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }

  &:hover {
    background-color: $neutral_20;
  }

  &.active {
    background-color: $primary;
    color: white;

    &:hover {
      background-color: $primary;
    }
  }

  &.disabled {
    background-color: $neutral_10;
    cursor: unset;

    &.active {
      background-color: $neutral_30;
    }
  }
}

.expandButton {
  min-height: 28px;
  min-width: 40px;
}

.arrowLeft {
  transform: rotate(90deg);
}

.arrowRight {
  transform: rotate(270deg);
}

.input {
  display: none;
}
