@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.fieldset {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}

.input {
  margin-bottom: 24px;
}
