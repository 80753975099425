@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';

.stats {
  grid-area: result;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px 30px 20px 30px;
  box-shadow: $shadow-s;
  border-radius: 8px;
}

.select {
  align-self: flex-end;
  @include text-body1-medium;
  color: $gray-800;
  border: none;
  cursor: pointer;
  margin-bottom: 8px;
  margin-right: 16px;

  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 24px;
  &::-ms-expand {
    display: none;
  }
  background: url('../../../../assets/svg/arrow_triangle.svg') no-repeat right transparent;
  background-size: 32px;

  &:focus-visible {
    outline: none;
  }
}
