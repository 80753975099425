@import '~crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/colors';

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include text-caption-caps;
  color: $cyan-500;
  margin: auto;
  cursor: pointer;

  svg {
    $size: 48px;
    height: $size;
    width: $size;
    fill: $cyan-500;
  }
}
