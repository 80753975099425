@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.cell {
  position: relative;

  .controls {
    display: none;
    justify-content: center;
    align-items: center;
    background: $gray-500;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    button {
      margin: 0;

      &:hover,
      &:focus {
        background-color: $gray-500;
        border: none;
      }

      svg {
        fill: white;
      }
    }
  }

  &:hover .controls {
    display: flex;
  }

  &[data-edit='true'] .controls {
    display: none;
  }

  &[data-highlight='true'] {
    color: $gray-500;
  }
}
