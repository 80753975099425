.goalConfig {
  max-width: 360px;
}

.input,
.targetArpu {
  margin-bottom: 24px;
}

.targetArpu {
  width: 88px;

  p {
    white-space: nowrap;
  }
}

.multiInputEl {
  margin-bottom: unset;

  div {
    width: 88px;
    margin-right: 12px;
  }
}

.targetArpu,
.multiInputEl {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.hint {
  $size: 24px;
  height: $size;
  width: $size;
  cursor: pointer;
}

.note {
  white-space: nowrap;
}
