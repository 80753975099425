/* Third-party libs */
@import 'src/ui/scss/vendor/bootstrap-custom';
@import 'src/ui/scss/vendor/toastify-custom';
@import '~crazy-ui/build/styles.css';
@import '~crazy-ui-next/build/styles.css';
@import '~react-datepicker/src/stylesheets/datepicker';
@import '~react-toastify/dist/ReactToastify.min.css';

/* Utils */
@import '~crazy-ui/src/scss/common/vars';
@import '~crazy-ui/src/scss/common/mixins';

/* Components */
@import 'src/ui/scss/components/round-image';
@import 'src/ui/scss/components/paper';
@import 'src/ui/scss/components/page-title';
@import 'src/ui/scss/components/filter-controls';
@import 'src/ui/scss/components/status-circle';

@import 'src/ui/scss/components/loader';
@import 'src/ui/scss/components/user-config';
@import 'src/ui/scss/components/table';

/* Pages */
@import 'src/ui/scss/pages/login';
@import 'src/ui/scss/components/experiment-list';
@import 'src/ui/scss/components/experiment-form';
@import 'src/ui/scss/components/experiment-preview';
@import 'src/ui/scss/pages/report';

// TODO: Move to crazy-ui-next
body {
  font-family: 'Rubik', sans-serif;
  background-color: #f2f5f5;
}
