$editor-input-label-width: 150px;

.input {
  margin-bottom: 0;

  &.dropdownInput {
    min-width: 300px;
  }
}

.multiInputEl {
  margin-bottom: unset;

  div {
    width: 88px;
    margin-right: 12px;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
}

.editorInputLabel {
  min-width: $editor-input-label-width;
}
