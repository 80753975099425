.loader {
  &-page {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    padding: 5rem 0;

    svg {
      fill: $primary;
      width: 5rem;
      height: 5rem;
    }
  }

  &-block {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin: 2rem 0;

    svg {
      width: 5rem;
      height: 5rem;
    }
  }
}
