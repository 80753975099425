@import '~crazy-ui-next/src/scss/common/colors';
@import '~crazy-ui-next/src/scss/common/vars_old';

.chartWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100%;
}

.chartFullscreenIcon {
  position: absolute;

  right: 20px;
  z-index: $z-index-fixed;

  svg {
    width: 2rem;
    height: 2rem;
    fill: $gray-300;

    &:hover {
      fill: $cyan-500;
    }
  }
}

.chartPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 63px;
  margin: auto;

  svg {
    width: 2rem;
    height: 2rem;
  }
}
