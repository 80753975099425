@import 'node_modules/crazy-ui/src/scss/common/vars';
@import 'node_modules/crazy-ui/src/scss/common/mixins';
@import 'node_modules/crazy-ui/src/scss/common/typography';

.roles {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-right: 10px;
}

.roleItem {
  $height: 40px;
  position: relative;
  display: inline-flex;
  background-color: $neutral_10;

  line-height: $height;
  padding: 0 1.25rem;
  border-radius: 2rem;

  align-items: center;
  justify-content: center;

  margin-right: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  &:last-child {
    margin-right: unset;
  }

  .name {
    color: rgba(black, 0.75);
  }
}

$button-size: 34px;

.addRoleBtn {
  display: inline-flex;
  align-self: center;
  width: $button-size;
  height: $button-size;
  padding: 0 !important;
  border-radius: 50% !important;
  border-width: 2px;
  outline: none;

  svg {
    width: 18px;
    height: 18px;
  }
}
