@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';
@import 'node_modules/crazy-ui-next/src/scss/common/z-index';

.select {
  :global {
    .select {
      &__control {
        align-items: center;
        background-color: #fff;
        border: 1px solid #c4d6d6;
        border-radius: 10px;
        box-shadow: $shadow-xs;
        cursor: pointer;
        display: flex;
        padding: 0 6px;
        position: relative;
        -webkit-user-select: none;
        user-select: none;
        min-height: 42px;
        color: $gray-800;

        &:hover {
          box-shadow: $shadow-s;
          border-color: $cyan-500;
        }

        &--menu-is-open {
          border-color: $cyan-500;
          border-bottom: unset;
          border-bottom: unset;
          border-radius: 10px 10px 0 0;
        }

        &--is-disabled {
          background-color: $gray-50;
          color: $gray-300;
        }
      }

      &__single-value {
        @include text-body2;
        color: $gray-800;
      }

      &__placeholder {
        @include text-body2;
        color: $gray-300;
      }

      &__menu {
        border: 1px solid $cyan-500;
        border-top: unset;
        box-shadow: unset;
        margin-top: unset;
        border-radius: 0 0 10px 10px;
        padding: 4px;
        z-index: $z-index-popover;
      }

      &__option {
        @include text-body2;
        align-items: center;
        border-radius: 8px;
        color: $gray-800;
        display: flex;
        height: 36px;
        padding: 0 12px;
        width: 100%;

        &:hover,
        &--is-focused,
        &--is-selected {
          background-color: #ebf2f1;
        }
      }

      &__multi-value {
        background: $cyan-100;
        color: $cyan-500;

        &__remove {
          svg {
            fill: $cyan-700;
          }
        }
        &--is-disabled {
          opacity: 0.5;
        }
      }

      &__dropdown-indicator {
        padding: unset;

        svg {
          $size: 32px;
          width: $size;
          height: $size;
          fill: $gray-500;
        }
      }
    }
  }
}
