.button {
  padding: 0 !important;
  border: none;
  width: 40px;
  height: 40px;
  svg {
    width: 32px;
    height: 32px;
  }
}
