@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.main {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  cursor: pointer;

  &:hover svg {
    opacity: 0.75;
  }

  &[data-active='true'] {
    &[data-dir='asc'] > svg path:last-of-type {
      opacity: 0.5;
    }

    &[data-dir='desc'] > svg path:first-of-type {
      opacity: 0.5;
    }

    & > svg {
      opacity: 1;
    }
  }

  > svg {
    position: absolute;
    right: -22px;
    fill: $gray-100;
    width: 32px;
    height: 32px;
    opacity: 0.5;
  }
}
