@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.table {
  margin: 20px 0;

  $key-col: minmax(150px, 1fr);
  $title-col: minmax(100px, 1fr);
  $type-col: 100px;
  $default-col: 125px;
  $min-col: 110px;
  $max-col: 110px;
  $session-col: 100px;
  $enabled-col: 100px;

  tr {
    grid-template-columns:
      $key-col
      $title-col
      $min-col
      $type-col
      $default-col
      $max-col
      $session-col
      $enabled-col;
  }
}
