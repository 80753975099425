@import '~crazy-ui-next/src/scss/common/colors';

.status {
  $svg-size: 32px;
  height: $svg-size;
  width: $svg-size;
  fill: $cyan-500;
  cursor: pointer;

  &[data-status='isRequest'] {
    animation: 1s ease-in infinite loading;
  }

  &[data-status='disabled'] {
    fill: $gray-500;
    cursor: not-allowed;
  }
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
