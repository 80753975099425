@import '~crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/colors';
@import '~crazy-ui-next/src/scss/common/shadows';

.section {
  display: flex;
}

.mapWrapper {
  width: 360px;
  margin-right: 1rem;
  margin-top: 6px;
}

.mapView {
  width: auto;
}

.stats {
  flex: auto;
  color: $gray-800;

  ul {
    @include text-body2;
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 228px;
    overflow-y: scroll;
    padding-right: 4px;

    li {
      display: flex;
      justify-content: space-between;

      span:last-child {
        color: $gray-500;
      }
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $gray-500;
    }
  }
}

.headers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include text-body1;
  color: $gray-500;
}

.controls {
  display: flex;

  button:disabled,
  button:focus {
    background-color: unset;
    border: unset;
    box-shadow: unset;
  }
}

.prev {
  transform: rotate(270deg);
}

.next {
  transform: rotate(90deg);
}

.placeholderWrapper {
  height: 150px;
  display: grid;
  grid-template-columns: 360px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 12px;
  margin-bottom: 24px;

  .placeholder {
    justify-self: stretch;
    display: grid;
    align-items: center;
    justify-content: center;
    background-color: $gray-50;
    border-radius: 10px;
    box-shadow: $shadow-xs;
  }

  span {
    @include text-h4;
    color: $gray-500;
  }
}
