@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.icon {
  $size: 24px;
  width: $size;
  height: $size;
  fill: $cyan-500;
}

.highlightIcon {
  @extend .icon;
  fill: $magenta-500;
}
