@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';

$row-color-set: (
  'green': $green-500,
  'yellow': $yellow-500,
  'red': $magenta-500,
  'blue': $cyan-500
);

$cell-default-width: 100px;
$cell-wider-width: $cell-default-width * 2;

.wrapper {
  display: flex;
  background-color: $gray-50;
  border-radius: 10px;
  margin-bottom: 24px;
  overflow: auto;

  .note {
    @include text-h4;
    color: $gray-800;
    margin: auto;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $gray-500;
  }

  table {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    margin: 10px;

    thead {
      display: grid;
      grid-template-rows: repeat(auto-fill, 52px);
      @include text-caption-caps;
      color: $gray-500;

      tr {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 1fr;
        grid-auto-flow: column;

        th {
          display: grid;
          text-align: center;
          align-items: center;
          width: $cell-default-width;

          &[data-wider='true'] {
            width: $cell-wider-width;
          }
        }
      }
    }

    tbody {
      display: grid;
      grid-template-rows: repeat(auto-fill, 52px);
      grid-template-columns: 1fr;
      grid-row-gap: 6px;
      @include text-body2;
      color: $gray-800;
      width: 100%;
      z-index: 1;

      tr {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 1fr;
        position: relative;
        background-color: white;
        border-radius: 5px;
        box-shadow: $shadow-s;
        grid-auto-flow: column;
        height: 52px;
        overflow: hidden;

        td {
          display: grid;
          text-align: center;
          justify-content: center;
          align-items: center;
          width: $cell-default-width;

          &[data-wider='true'] {
            width: $cell-wider-width;
          }

          &[data-align='start'] {
            text-align: start;
            justify-content: flex-start;
          }

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            height: 32px;
            width: 32px;
            color: $gray-600;
            background-color: $gray-300;
          }

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &:before {
          content: '';
          position: absolute;
          width: 8px;
          height: 100%;
          background-color: $gray-500;
        }

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: white;
          z-index: -1;
        }

        @each $key, $color in $row-color-set {
          &[data-color='green'] {
            background-color: rgba($green-100, 0.3);

            &:before {
              background-color: $green-500;
            }

            &:hover {
              background-color: rgba($green-100, 0.4);
            }
          }

          &[data-color='yellow'] {
            background-color: rgba($yellow-100, 0.3);

            &:before {
              background-color: $yellow-500;
            }

            &:hover {
              background-color: rgba($yellow-100, 0.4);
            }
          }

          &[data-color='red'] {
            background-color: rgba($magenta-100, 0.3);

            &:before {
              background-color: $magenta-500;
            }

            &:hover {
              background-color: rgba($magenta-100, 0.4);
            }
          }

          &[data-color='blue'] {
            background-color: rgba($cyan-100, 0.3);

            &:before {
              background-color: $cyan-500;
            }

            &:hover {
              background-color: rgba($cyan-100, 0.4);
            }
          }
        }
      }
    }
  }
}
