.page-title {
  background-color: white;
  border-bottom: 1px solid $border-color;

  &__text {
    padding: 1.75rem 0 1.125rem 0;
    color: $text-darker;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2rem;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__custom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
