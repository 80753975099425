@import '~crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/colors';

@import '../CreateExperiment.module';

.radioInputGroup {
  display: flex;
  margin-bottom: 24px;

  & > * {
    flex: 1;
  }
}

.input {
  margin-bottom: 24px;
}
.checkbox {
  margin: 32px 16px 24px;
}

.basicInfo {
  max-width: 360px;
}

.compactDropdown {
  ul {
    max-height: 300px;
    overflow: auto !important;

    button {
      height: 36px !important;
    }
  }
}
