@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.wrapper {
  min-width: unset;
  width: 370px;
  color: $text-color;

  form {
    h3 {
      @include text-h4;
      font-size: 16px;
      margin: 12px 0;
      padding: 0 9px;
    }

    h4 {
      @include text-h4;
      margin: 4px 0 24px 0;
    }

    .inputsWrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;

      .textInput {
        width: 88px;
        margin: 0;
      }

      .checkboxWithMargin {
        margin-top: 26px;
      }

      .plainCheckbox {
        margin-left: 9px;
      }
    }

    .addSessionBtn {
      margin-bottom: 6px;
    }

    .removeSessionBtn {
      svg {
        fill: $alert-error;
      }
    }

    .controls {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 24px;

      button {
        width: 94px;
      }
    }
  }
}
