@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';

$radius: 10px;

.tableWrapper {
  overflow: auto;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-500;
  }
}

.table {
  box-shadow: $shadow-xs;
  @include text-body2;
  border-radius: $radius;
  overflow: hidden;
  margin-bottom: 24px;
  color: $gray-800;

  thead {
    @include text-caption;

    th {
      background: $gray-100;

      &:first-child {
        border-top-left-radius: $radius;
      }
      &:last-child {
        border-top-right-radius: $radius;
      }
    }
  }

  tbody {
    tr {
      td {
        &[data-highlight='true'] {
          color: $gray-500;
        }
      }
    }

    tr:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: $radius;
        }
        &:last-child {
          border-bottom-right-radius: $radius;
        }
      }
    }
  }

  th,
  td {
    padding: 1rem;
    border: 1px solid $gray-300;
  }

  p {
    text-align: center;
  }
}

.summary {
  margin-bottom: 16px;

  li {
    p {
      @include text-body2;
      padding-bottom: 4px;
      color: $gray-800;

      span {
        color: $cyan-500;
      }
    }
  }
}

.filesSummary {
  li {
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      fill: $text-color;
    }
  }
}
