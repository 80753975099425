@import '~crazy-ui/src/scss/common/vars';

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: $text-lighter;
  font-size: 12px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $text-lighter;
}

.separator:not(:empty)::before {
  margin-right: 0.7em;
}

.separator:not(:empty)::after {
  margin-left: 0.7em;
}
