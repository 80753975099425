@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';
@import 'node_modules/crazy-ui-next/src/scss/common/z-index';

.form {
  &:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid $gray-200;
    margin-bottom: 24px;
  }

  .title {
    @include text-body1-medium;
    text-transform: uppercase;
    color: $cyan-500;
    margin-bottom: 22px;

    &.highlight {
      &:hover {
        color: $cyan-300;
        cursor: pointer;
      }
    }
  }
}

.input {
  margin-bottom: 24px;
}

.fieldset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.dropdown {
  width: 305px;
  z-index: auto;

  &[data-open='true'] {
    z-index: $z-index-popover;
  }
}

.nextBtn {
  margin-top: 48px;

  svg {
    transform: rotate(90deg);
  }
}
