@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';

.filters {
  display: flex;
  align-items: flex-end;
  gap: 12px;
  margin-bottom: 16px;
}

.input {
  max-width: 180px;
  margin-bottom: unset;
}

.dateRangeWrapper {
  margin-right: auto;

  .dateRange {
    @include text-body2;
    color: $gray-800;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: #fff;
    border: 1px solid $gray-300;
    height: 42px;
    border-radius: 10px;
    box-shadow: $shadow-xs;
    padding: 0 6px;
    -webkit-user-select: none;
    user-select: none;
    margin-right: auto;

    button {
      $size: 32px;
      height: $size;
      width: $size;
      padding: unset;

      &.next {
        svg {
          transform: rotate(180deg);
        }
      }

      svg {
        height: $size;
        width: $size;
        fill: $gray-200;
      }

      &:not(:disabled) {
        cursor: pointer;

        svg {
          fill: $gray-500;
        }

        &:hover {
          svg {
            fill: $cyan-500;
          }
        }
      }
    }
  }

  .accurate {
    color: $green-500;
  }
  .inaccurate {
    color: $magenta-500;
  }
}

.tooltipIcon {
  height: 42px;
  width: 42px;
}
