@import 'node_modules/crazy-ui-next/src/scss/common/z-index';

.input {
  max-width: 360px;
  margin-bottom: 24px;
}

.multiInputEl {
  margin-bottom: unset;

  div {
    width: 88px;
    margin-right: 12px;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;

  &.multiInputWrapper {
    margin-bottom: 16px;
  }
}

.targetConfig {
  max-width: 600px;

  .versions {
    align-items: flex-end;
    margin-bottom: 24px;
  }
}

.compactDropdown {
  ul {
    max-height: 300px;
    gap: 16px;
    overflow: auto !important; //fixme move to crazy-ui-next(?)
  }
}

.dropdown {
  width: 100px;
  &[data-open='true'] {
    z-index: $z-index-popover;
  }
}
