/*!
 * Bootstrap Grid v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

$grid-columns: 16 !default;
$grid-gutter-width: 20px !default;
$grid-row-columns: 6 !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px // 1260px + $grid-gutter-width
) !default;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/grid-framework';
@import '~bootstrap/scss/mixins/grid';

@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/utilities/display';
@import '~bootstrap/scss/utilities/flex';
@import '~bootstrap/scss/utilities/spacing';
