@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';

.logs {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.dropdown {
  position: absolute;
  width: 460px;
}

.view {
  div {
    background-color: white;
  }

  :global {
    .react-lazylog-searchbar {
      padding: 24px 0 16px;

      &:after {
        position: absolute;
        content: '';
        background: $gray-500;
        height: 32px;
        width: 32px;
        top: 29px;
        right: 327px;
        -webkit-mask-image: url('../../../../../../assets/svg/search.svg');
        mask-image: url('../../../../../../assets/svg/search.svg');
      }
      &:before {
        position: absolute;
        content: 'Search';
        @include text-caption-caps;
        color: $gray-500;
        top: 6px;
        right: 305px;
      }

      input {
        height: 42px;
        width: 300px;
        background: white;
        border: 1px solid $gray-300;
        border-radius: 10px;
        box-shadow: $shadow-xs;
        padding: 0 6px 0 38px;
        @include text-body2;
        color: $gray-800;
        position: relative;

        &::placeholder {
          opacity: 0.5;
        }

        &:hover,
        &:active,
        &:focus {
          outline: none;
          box-shadow: $shadow-s;
        }
      }

      button {
        height: 42px;
        width: 42px;
        border-radius: 10px;
        background: $cyan-500;
        position: relative;
        cursor: pointer;

        svg {
          fill: $cyan-500;
        }

        &:after {
          position: absolute;
          content: '';
          top: 5px;
          left: 5px;
          width: 32px;
          height: 32px;
          background: $cyan-700;
          -webkit-mask-image: url('../../../../../../assets/svg/eye.svg');
          mask-image: url('../../../../../../assets/svg/eye.svg');
        }

        &:hover {
          background: $cyan-400;
          &:after {
            background: white;
          }
        }
      }

      span {
        position: absolute;
        color: $cyan-500;
        top: 0;
        right: 62px;
      }
    }

    .ReactVirtualized__Grid__innerScrollContainer {
      div {
        color: $gray-600;

        &:hover {
          background: $gray-800;
          color: $gray-50;
        }
      }
    }
  }
}
