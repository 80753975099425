.report {
  display: flex;
  flex-direction: column;

  $block-color: $background-green;
  $block-margin: 1.25rem;
  $block-padding: 1rem;

  &__game-title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .label {
      color: $text-color;
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: $block-padding;
    margin-bottom: $block-margin;
    background-color: $block-color;
  }

  &__header-block {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-basis: 45%;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    @include block-text;

    a {
      font-size: 1rem;
      margin-left: 1rem;
    }
  }

  &__description {
    @include subtitle-text;
    line-height: 2rem;
  }

  &__header-info {
    display: flex;
    align-items: center;
    flex-basis: 50%;
  }

  .status-circle {
    $size: 2rem;
    width: $size;
    height: $size;
    margin-right: 1.125rem;
    svg {
      width: $size;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;

    .label {
      @include note-text;
    }

    .value {
      color: $text-color;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }

  .game-score {
    display: flex;
    margin-left: auto;
    font-size: 1.25rem;
    color: $text-color;
    align-items: center;

    .round-score {
      margin-left: 1rem;
    }
  }

  &__data-block {
    display: flex;
  }

  &__conclusion {
    display: flex;
    background-color: $block-color;
    padding: $block-padding;
    margin-top: $block-margin;

    .conclusion {
      &__label {
        @include note-text;
        margin-right: 1.25rem;
      }
    }
  }

  &__chart {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    padding: 1.75rem;
    flex-basis: 45%;
    background-color: $block-color;
  }

  &__data {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: $block-margin;
  }

  .media {
    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      display: flex;
      flex-direction: column;
      margin-top: 1.5rem;
      margin-right: 1.5rem;

      .title {
        font-size: 1.125rem;
        line-height: 2rem;
        text-align: center;
        color: $primary;
      }

      .preview {
        $color: lighten($primary, 5%);
        display: flex;
        justify-content: center;
        align-content: center;
        width: 105px;
        height: 70px;
        border: 2px solid $color;
        fill: $color;
        cursor: pointer;

        &:hover {
          border-color: lighten($primary, 10%);
          fill: lighten($primary, 10%);

          svg {
            transform: scale(1.1);
          }
        }

        svg {
          width: 45%;
          height: auto;
        }
      }
    }
  }

  .chart {
    &__container {
      max-width: 275px;
      margin: 2rem auto 0 auto;
    }

    &__text {
      margin: 1rem 0 3.75rem 0;
      text-align: center;
      font-size: 1.5rem;
      line-height: 2rem;
      color: $text-color;
    }
  }

  .legend {
    display: flex;
    justify-content: space-evenly;

    margin: 0 2rem;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .square {
        display: inline-flex;
        margin-right: 0.5rem;
        width: 1rem;
        height: 1rem;
        background-color: $neutral_40;
      }

      .title {
        display: flex;
        align-items: center;
        color: $text-lighter;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      .value {
        color: $text-color;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }
  }
}
