@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

$t-row-width: 100%;
$t-row-height: 52px;
$t-row-radius: 6px;
$t-row-gap: 10px;

$t-col-gap: 8px;

$icon-size: 36px;
$row-color: white;
$header-color: $gray-500;

@mixin background-gradient($width, $height) {
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #e7e7e7;
  background-repeat: repeat-y;
  background-size: $width $height;
  background-position: 0 0;
  animation: shine 1.6s infinite;
}

@keyframes shine {
  to {
    background-position: 120% -10%, /* move highlight to right */ 0 0;
  }
}

.table {
  display: flex;
  flex-direction: column;
  min-height: 80vh;

  thead {
    margin-bottom: $t-row-gap;

    th > button,
    th {
      @include text-body2-medium;
      color: $gray-100;
      fill: $gray-100;
      text-transform: uppercase;
      align-self: center;
      text-align: center;
    }

    th {
      &[data-cell='title'] {
        text-align: left;
      }
    }
  }

  thead tr {
    background-color: $header-color;
    min-height: $t-row-height;
  }

  tbody tr {
    background-color: $row-color;
    min-height: $t-row-height;
    cursor: pointer;

    &:hover {
      outline: 1px solid $cyan-500;
    }
  }

  tr {
    display: inline-grid;
    grid-template-columns: auto;
    grid-column-gap: $t-col-gap;
    width: $t-row-width;

    border-radius: $t-row-radius;
    box-shadow: 0 2px 4px rgba(black, 0.16);

    padding: 0 $t-row-gap;
    margin-bottom: $t-row-gap;

    &:last-child {
      margin-bottom: unset;
    }

    &[data-loading='true'] {
      align-items: center;
      justify-items: center;

      td[data-cell='title'],
      td[data-cell='text'],
      td[data-cell='date'],
      td[data-cell='flag'],
      td[data-cell='label'] {
        $height: 1rem;
        width: 75%;
        height: $height;
        border-radius: 8px;
        @include background-gradient(1rem, $height);
      }

      td[data-cell='title'] {
        justify-self: flex-start;
      }

      td[data-cell='flag'] {
        border-radius: 4px;
        width: 16px;
      }

      td[data-cell='icon'] {
        span {
          @include background-gradient(1rem, $icon-size);
        }
      }
    }
  }

  td {
    @include text-body2-medium;
    color: $text-color;

    &[data-cell='date'],
    &[data-cell='title'],
    &[data-cell='text'] {
      @include text-body2;
      line-height: 1rem;
      color: $text-color;
      display: flex;
      align-items: center;
      word-break: break-word;
      padding-top: $t-col-gap;
      padding-bottom: $t-col-gap;
    }

    &[data-cell='icon'] {
      border-top-left-radius: $t-row-radius;
      border-bottom-left-radius: $t-row-radius;
      height: $t-row-height;
      min-width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      img,
      span {
        object-fit: cover;
        border-radius: 5px;
        height: $icon-size;
        width: $icon-size;
      }
    }

    &[data-cell='title'] {
      justify-content: flex-start;
      letter-spacing: 0;
    }

    &[data-cell='text'] {
      justify-content: center;
    }

    &[data-cell='flag'] {
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }

    &[data-cell='date'] {
      display: inline-flex;
      justify-content: center;
      flex-direction: column;
      line-height: 2px;
    }

    &[data-cell='label'] {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a {
      color: $text-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    svg {
      $size: 32px;
      width: $size;
      height: $size;
      fill: $primary;
    }
  }
}
