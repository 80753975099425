@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.main {
  @include text-body2-medium;
  color: $text-color-5;

  > span {
    margin: 0 2px;
    color: $text-color-3;
  }
}
