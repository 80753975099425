@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/z-index';

.wrapper {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .inputs {
    display: flex;
    gap: 8px;

    div {
      margin-bottom: 0;
    }

    svg {
      fill: $secondary;
    }
  }

  .dropdown {
    &[data-open='true'] {
      z-index: $z-index-popover;
    }
  }

  .controls {
    position: absolute;
    display: flex;
    gap: 8px;
    margin-left: 8px;
    margin-top: 4px;

    top: 0;
    left: 100%;
  }

  button {
    min-width: unset;
    width: 32px;
    height: 32px;

    &:not([disabled]).minus {
      fill: $magenta-500;
    }
  }
}
