@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.audit {
  background-color: $gray-300;
  border-radius: 10px;
  border-collapse: collapse;
  border: 1px solid $gray-300;
  overflow: hidden;
  margin-top: 16px;

  thead {
    @include text-caption-caps;
    height: 42px;
    background-color: $gray-50;
    border-radius: 10px 10px 0 0;

    tr {
      th {
        padding: 10px;
        border: 1px solid $gray-300;
      }
    }
  }

  tbody {
    @include text-body2;
    color: $gray-800;
    border-radius: 0 0 10px 10px;

    tr {
      height: 32px;
      background-color: white;
      td {
        padding: 10px;
        border: 1px solid $gray-300;

        pre {
          background-color: white;
        }
      }
    }
  }
}
