@import '~crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/colors';
@import '~crazy-ui-next/src/scss/common/shadows';

$form-width: 800px;
$notification-width: 400px;
$section-gap: 16px;
$controls-gap: 12px;
$header-height: 78px;

.main {
  display: flex;
  gap: 2rem;
  padding-top: 2rem;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: $section-gap;
  width: $form-width;
  margin-left: 3rem;
}

.notifications {
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: $section-gap;
  top: 2rem;
  width: $notification-width;
  overflow: scroll;
  height: calc(100vh - 4rem);
}

.section {
  background: white;
  width: 100%;
  box-shadow: $shadow-s;
  border-radius: 10px;

  .legend {
    display: flex;
    align-items: center;
    padding: 24px 22px 12px;
    color: $gray-800;

    h4 {
      @include text-h4-medium;
      margin-left: 12px;
    }

    button:disabled {
      background-color: unset;
      box-shadow: unset;
      border: unset;
    }
  }
  .content {
    padding: 0 78px 32px;

    &.hidden {
      display: none;
    }
  }
}

.controls {
  display: flex;
  gap: $controls-gap;
  padding: 26px 18px;
}

.nextBtn {
  margin-top: 48px;

  svg {
    transform: rotate(90deg);
  }
}

.summary {
  li {
    p {
      @include text-body2;
      padding-bottom: 4px;
      color: $text-color;

      span {
        color: $cyan-500;
      }
    }
  }
}
