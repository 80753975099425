@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

$row-height: 52px;
$row-radius: 6px;
$col-gap: 8px;

.wrapper {
  display: flex;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;

  padding: 0px 8px;
  background-color: #f2f5f5; // TODO: Add to colors?
  border-radius: 10px;
}

.winnersList,
.variablesList {
  display: flex;
  flex-direction: column;
  padding-top: $row-height;
  width: fit-content;
}

.winnersList {
  box-shadow: 2px 0 4px -2px rgba(black, 0.16);
  z-index: 1;
}

.variablesList {
  min-width: 55%;
  overflow-x: scroll;
  z-index: 0;

  .variableRow {
    min-width: 100%;
    justify-content: space-around;
  }
}

.winnerRow,
.variableRow {
  height: 52px;
  margin-bottom: 10px;
  width: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: $text-color;
  position: relative;

  box-shadow: 0 2px 4px rgba(black, 0.16);
  background-color: white;

  .col {
    min-width: 120px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-800;

    .heading {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: $row-height;
      height: $row-height;
      max-width: 100%;
      overflow: hidden;
      line-height: 16px;
      padding: 6px;

      p {
        vertical-align: middle;
        white-space: break-spaces;
        text-align: center !important;
        text-overflow: ellipsis;
        color: $gray-500;
      }
    }

    &.applied {
      min-width: unset;
      width: 80px;
    }

    &.region {
      min-width: unset;
      width: 100px;
      color: #6e8e99;
    }

    &.group {
      .drop {
        margin-bottom: 0;
        width: 72px;
        z-index: auto;

        ul {
          max-height: 300px;
          top: -1px;
          border: 1px solid $primary;
          border-radius: 18px;
          overflow-y: scroll;
          z-index: 1;

          li {
            height: 34px;
            padding: 6px;

            &:hover {
              background-color: transparent;
              button {
                color: $primary;
              }
            }
          }
        }

        div {
          border-radius: 18px;
        }

        input {
          min-width: unset;
          height: 32px;
          color: $primary;
        }
      }
    }
  }
}

.variableRow {
  border-radius: 0 $row-radius $row-radius 0;
}

.winnerRow {
  border-radius: $row-radius 0 0 $row-radius;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: $col-gap;
    background-color: $gray-500;
    border-top-left-radius: $row-radius;
    border-bottom-left-radius: $row-radius;
  }

  &.active {
    &:after {
      background-color: $primary;
    }
  }
}

ul::-webkit-scrollbar {
  height: 4px;
}

ul::-webkit-scrollbar-thumb {
  background: $gray-500;
}
