@use 'sass:math';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/shadows';

$radius: 10px;
$col-gap: 8px;
$input-height: 40px;
$input-spacer: 6px;
$icon-spacer: 6px;
$icon-size: 32px;
$shadow: $shadow-xs;

.wrapper {
  display: flex;
  flex-direction: column;
  min-width: 220px;
  max-width: 280px;
  width: 100%;

  .main {
    display: flex;
    align-items: center;

    padding: 0 0 0 $input-spacer;
    border-radius: $radius;
    border: 1px solid $gray-300;
    background-color: white;
    box-shadow: $shadow;

    &:focus-within {
      border-color: $cyan-400;
    }

    &:focus-within,
    &:hover {
      input::placeholder {
        color: $text-color-3;
      }

      .icon svg {
        fill: $cyan-500;
      }
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      svg {
        fill: $gray-500;
        width: $icon-size;
        height: $icon-size;
      }
    }

    .display {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      :global {
        .react-datepicker-wrapper .react-datepicker__input-container,
        .react-datepicker-wrapper {
          display: inline-flex;
          justify-content: center;
        }
      }

      > svg {
        width: 8px;
        height: 20px;
        stroke: $gray-300;
      }

      input {
        @include text-body2;
        text-align: center;
        display: inline-flex;
        border: none;
        height: $input-height;
        width: 100%;
        background-color: transparent;
        color: $text-color;
        cursor: pointer;
        padding: 0;

        &::placeholder {
          color: $gray-300;
        }

        &:disabled {
          cursor: auto;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
