@import '~crazy-ui/src/scss/common/vars';

*,
*:before,
*:after {
  box-sizing: border-box;
}

.g-sign-in-button {
  margin-top: 10px;
  display: inline-block;
  height: 50px;
  color: $text-lighter;
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid $border-color-input;
}

.g-sign-in-button:hover {
  cursor: pointer;
  border: 2px solid $primary;
  color: $text-lighter;
}

.g-sign-in-button:active {
  color: $primary;
}

.g-sign-in-button .content-wrapper {
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
  text-align: center;
  position: relative;
}

.g-sign-in-button img {
  width: 18px;
  height: 18px;
}

.g-sign-in-button .logo-wrapper {
  padding: 15px;
  background: #fff;
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 6px;
  display: inline-block;
  left: 0px;
}

.g-sign-in-button .text-container {
  font-weight: 500;
  letter-spacing: 0.21px;
  font-size: 14px;
  line-height: 48px;
  vertical-align: top;
  border: none;
  display: inline-block;
  text-align: center;
  width: 180px;
}
