@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.platform,
.dates {
  display: grid;
  align-items: center;
  grid-template-columns: 32px 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0 8px;
  grid-auto-flow: row;
  grid-template-areas:
    'icon title'
    'icon value';
}

.firebase,
.type {
  display: flex;
  flex-direction: column;
}

.dates {
}

.icon {
  $size: 32px;
  grid-area: icon;
  fill: $cyan-500;
  width: $size;
  height: $size;
}

.title {
  grid-area: title;
  @include text-body1-medium;
  color: $gray-500;
}

.value {
  grid-area: value;
  @include text-body2;
  color: $gray-800;
}

.startDate {
  grid-area: title;
  @include text-body2;
  color: $gray-800;
}

.endDate {
  grid-area: value;
  @include text-body2;
  color: $gray-800;
}
