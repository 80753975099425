.round-image {
  width: 3.75rem;
  height: 3.75rem;
  font-size: 1rem;
  border-radius: 50%;

  &.empty {
    background-color: #dfebed;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__letters {
    user-select: none;
    font-weight: 700;
    font-size: 1.25em;
    opacity: 0.9;
    color: #33464a;
  }

  > img {
    border-radius: 50%;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.round-score {
  $size: 3rem;
  width: $size;
  height: $size;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $neutral_30;

  &.green {
    background-color: darken($status-green, 10%);
  }

  &.yellow {
    background-color: $status-yellow;
  }

  &.red {
    background-color: $status-red;
  }

  &.large {
    $size: 4.5rem;
    width: $size;
    height: $size;

    .round-score__numbers {
      line-height: $size;
      font-size: 2.5rem;
    }
  }

  &__numbers {
    user-select: none;
    line-height: $size;
    font-size: 1.5rem;
    opacity: 1;
    color: white;
  }
}
