@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';

.wrapper {
  grid-area: sanity;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px 16px 20px 30px;
  box-shadow: $shadow-s;
  border-radius: 8px;
  overflow: hidden;
  flex: 1;
  max-height: 226px;

  .headings {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  h5 {
    @include text-body1;
    color: $gray-800;
    font-weight: bold;
    text-transform: uppercase;
  }

  .select {
    @include text-body1-medium;
    color: $gray-800;
    border: none;
    cursor: pointer;

    -moz-appearance: none;
    -webkit-appearance: none;
    padding-right: 24px;
    &::-ms-expand {
      display: none;
    }
    background: url('../../../../assets/svg/arrow_triangle.svg') no-repeat right #ffffff;
    background-size: 32px;

    &:focus-visible {
      outline: none;
    }
  }

  .charts {
    flex: 1;
    display: flex;
    min-height: 60px;

    svg {
      height: 100%;
    }
  }
}
