.paper {
  margin-top: 1.5rem;
  padding: 1.75rem;
  width: auto;
  box-shadow: 0 3px 3px #e9e7e8;
  background-color: white;
  border-radius: 5px;

  &-75 {
    width: 75%;
  }

  &-50 {
    width: 50%;
  }

  &.no-margin {
    margin-top: unset;
  }

  &.mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
