@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/typography';

.modalWrapper {
  width: 800px;

  p {
    text-align: left;
  }

  .audience {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    color: $gray-800;

    .inputWrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      .dropdown {
        max-width: 100px;
        margin-bottom: unset;
      }

      &:not(:first-child) {
        margin-top: 24px;
      }

      .multiInputEl {
        margin-bottom: unset;

        div {
          width: 88px;
          margin-right: 12px;
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .title {
      display: flex;
      align-items: center;

      p {
        @include text-body1-medium;
        font-size: 24px;
        vertical-align: middle;
        color: $gray-800;
      }

      svg {
        height: 48px;
        width: 48px;
        fill: $primary;
        margin-right: 10px;
      }
    }

    .platform {
      display: flex;
      font-size: 14px;
      border-radius: 10px;
      background-color: #e0fcff;
      padding: 4px 11px 4px 8px;
      color: $gray-800;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 6px;
        fill: $primary;
      }
    }
  }

  .controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    gap: 12px;
  }
}
