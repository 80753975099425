@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

$row-height: 52px;
$row-radius: 6px;
$col-gap: 8px;

$icon-size: 36px;

@mixin background-gradient($width, $height) {
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #e7e7e7;
  background-repeat: repeat-y;
  background-size: $width $height;
  background-position: 0 0;
  animation: shine 1.6s infinite;
}

@keyframes shine {
  to {
    background-position: 120% -10%, /* move highlight to right */ 0 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
  min-height: 95vh;
  margin-top: $col-gap;

  $icon-col: $icon-size;
  $title-col: minmax(256px, 1fr);
  $stats-col: 162px;
  $platform-col: 256px;
  $region-col: 256px;
  $control-col: 40px;

  .row {
    display: grid;
    justify-content: flex-start;
    align-items: center;

    grid-template-columns:
      $icon-col
      $title-col
      $stats-col
      $stats-col
      $platform-col
      $region-col
      $control-col;

    grid-template-rows: $row-height auto;

    grid-column-gap: $col-gap;
    padding: 0 $col-gap 0 $col-gap * 2;
    min-height: $row-height;
    margin-bottom: $col-gap;
    cursor: pointer;

    > table {
      grid-column: 1 / 8;
    }
  }
}

.list > li {
  width: 100%;
  border-radius: $row-radius;
  box-shadow: 0 2px 4px rgba(black, 0.16);
  background-color: white;
}

.row {
  @include text-body2-medium;
  color: $text-color;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: $col-gap;
    background-color: $gray-500;
    border-top-left-radius: $row-radius;
    border-bottom-left-radius: $row-radius;
  }

  &[data-open='true']:after {
    background-color: $cyan-500;
  }

  &:hover {
    &[data-open='false']:after {
      background-color: $cyan-500;
    }

    &[data-open='true']:after {
      background-color: $cyan-400;
    }

    .control svg {
      fill: $cyan-500;
    }
  }

  svg {
    $size: 32px;
    width: $size;
    height: $size;
    fill: $primary;
  }

  label {
    text-transform: uppercase;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    span {
      object-fit: cover;
      border-radius: 5px;
      height: $icon-size;
      width: $icon-size;
    }
  }

  .platform {
    justify-content: center;
  }

  .region {
    > div[data-count] {
      justify-content: flex-start;

      > div {
        margin-right: -4px;
      }
    }
  }

  .stats,
  .platform,
  .region {
    display: flex;
    align-items: center;
  }

  .region,
  .platform {
    label {
      margin-right: 12px;
    }
  }

  .stats {
    > span {
      @include text-h3;
      color: $primary;
      margin-right: 12px;
      text-transform: uppercase;
    }
  }

  .control {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      padding: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        fill: $gray-500;
        transform: rotate(180deg);
      }

      &:hover svg {
        fill: $cyan-500;
      }

      &[data-open='true'] svg {
        transform: rotate(0deg);
      }
    }
  }
}

.row[data-loading='true'] {
  .icon span {
    @include background-gradient(1rem, $icon-size);
  }

  > p {
    height: 1rem;
    width: 70%;
    border-radius: 8px;
    @include background-gradient(1rem, 70%);
  }

  .stats {
    span {
      height: 28px;
      width: 14px;
      border-radius: 4px;
      @include background-gradient(14px, 28px);
    }

    label {
      height: 1rem;
      width: 70px;
      border-radius: 8px;
      @include background-gradient(1rem, 70px);
    }
  }

  .platform {
    span {
      height: 32px;
      width: 32px;
      border-radius: 8px;
      @include background-gradient(32px, 32px);
    }

    label {
      height: 1rem;
      width: 75px;
      border-radius: 8px;
      @include background-gradient(1rem, 70px);
    }
  }

  .region {
    span {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      @include background-gradient(32px, 32px);
    }

    label {
      height: 1rem;
      width: 54px;
      border-radius: 8px;
      @include background-gradient(1rem, 54px);
    }
  }

  .control {
    span {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      @include background-gradient(30px, 30px);
    }
  }
}

.innerTable {
  @include text-body2-medium;
  color: $text-color;
  overflow: hidden;

  &[data-open='true'] {
    max-height: 100%;
    margin-bottom: 24px;
  }

  &[data-open='false'] {
    max-height: 0;
    visibility: hidden;
  }
}

.innerTable > table {
  width: 100%;
  padding: 8px;

  $name-col: minmax(210px, 1fr);
  $type-col: 120px;
  $group-col: 95px;
  $region-col: 76px;
  $version-col: 140px;
  $date-col: 124px;
  $status-flag-col: 74px;
  $user-col: 74px;
  $control-col: 120px;

  thead {
    text-transform: uppercase;
    color: $gray-500;
  }

  tr {
    min-height: $row-height;
    display: grid;
    align-items: center;
    border-bottom: 1px solid $gray-200;
    padding: 0 26px;

    grid-template-columns:
      $name-col
      $type-col
      $group-col
      $region-col
      $version-col
      $date-col
      $status-flag-col
      $user-col
      $control-col;

    grid-column-gap: $col-gap;
    width: 100%;
  }

  td,
  th {
    text-align: center;
    justify-content: center;

    &[data-cell='title'] {
      text-align: left;
      justify-content: flex-start;
    }

    &[data-cell='text'] {
      word-break: break-word;
    }

    &[data-cell='flag'] {
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }

    &[data-cell='control'] {
      display: flex;
      justify-content: center;
    }
  }

  tbody tr {
    border-bottom: 1px solid $gray-200;

    &:hover {
      border-radius: $row-radius;
      border-color: transparent;
      background-color: #f2f5f5; // TODO: Add to colors?
    }

    &:last-child {
      border-bottom: unset;
    }
  }
}
