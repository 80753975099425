@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.inputGroup {
  display: grid;
  grid-template-columns: min-content minmax(90px, 116px);
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-500;
  }

  button {
    max-width: fit-content;
  }
}

.table {
  color: $gray-800;

  thead {
    background: $gray-100;
    @include text-caption;

    th {
      height: 45px;
      padding-left: 8px;
      border: 1px solid $gray-300;
      position: relative;

      div {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      input {
        border: none;
        outline: none;
        background: transparent;
      }
    }
  }
  tbody {
    @include text-body2;
    td {
      height: 60px;
      padding: 8px;
      border: 1px solid $gray-300;
      min-width: 84px;
      white-space: nowrap;

      &[data-remove='true'] {
        div {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        button {
          opacity: 0;
          transition: 0.3s ease-out;
        }

        &:has(div):hover {
          background: $gray-500;
          color: white;

          button {
            opacity: 1;
          }
        }
      }
    }
  }
}

.addVarWrapper {
  div {
    display: flex;
    * {
      max-width: 100px;
    }
  }
}

.editableCell {
  position: relative;

  &.error {
    border-color: $magenta-500;
  }

  .controls {
    display: none;
    justify-content: center;
    align-items: center;
    background: $cyan-100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover .controls {
    display: flex;
  }

  &[data-edit='true'] .controls {
    display: none;
  }

  &[data-highlight='true'] {
    color: $gray-500;
  }

  input {
    border: unset;
    outline: none;
    color: inherit;
    width: 100px;
    background-color: transparent;
  }
}
