@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.editableCell {
  position: relative;

  &.error {
    border-color: $magenta-500;
  }

  .controls {
    display: none;
    justify-content: center;
    align-items: center;
    background: $cyan-100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    button {
      margin: 0;
    }
  }

  &:hover .controls {
    display: flex;
  }

  &[data-edit='true'] .controls {
    display: none;
  }

  &[data-highlight='true'] {
    color: $gray-500;
  }
}
