@import '~crazy-ui/src/scss/common/vars';

.anchor {
  $size: 40px;

  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $size;

  svg {
    width: $size;
    height: $size;
  }
}
