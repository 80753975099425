.experiment-form {
  position: relative;
  .form {
    &__fields {
      max-width: unset;
    }

    &__group {
      max-width: 400px;

      &.big {
        //max-width: unset;
        max-width: 70%;
      }

      &.max {
        max-width: 95%;
      }
    }
  }
}
