@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.table {
  $icon-col: 36px;
  $rec-col: 100px;
  $title-col: minmax(140px, 1fr);
  $store-col: 50px;
  $e-type-col: 125px;
  $e-name-col: minmax(140px, 2fr);
  $e-status-col: 125px;
  $e-date-col: 100px;
  $e-country-col: 100px;
  $e-winner-col: 90px;

  thead {
    th {
      &:first-child {
        grid-column: 1 / 3;
        text-align: left;
      }
    }
  }

  tr {
    grid-template-columns:
      $icon-col
      $title-col
      $rec-col
      $e-name-col
      $store-col
      $e-type-col
      $e-status-col
      $e-date-col
      $e-country-col
      $e-winner-col;
  }
}
