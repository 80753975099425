@import '~crazy-ui/src/scss/common/vars';

$blue: $primary;
$light-blue: #f5fafb;
$dark-blue: #dfebee;

$z-index-hover: 3;
$z-index-top: 2;
$z-index-default: 1;
$z-index-bottom: 0;

$size: 2.25rem;
$circle-offset: 0.375rem; // 6px
$outline-size: 0.125rem; // 2px

$row-spacing: calc(0.125rem / 2); // 1px
$row-height: (2 * $row-spacing) + (2 * $outline-size) + $size;
$user-count: 6;

.filterWrapper {
  display: flex;
}

.wrapper {
  position: relative;
}

.divider {
  display: inline-flex;
  background-color: $neutral_20;
  width: 1px;
  align-self: stretch;
  margin: 0 0.25rem;
}

.list {
  display: flex;
  margin: 0 0.75rem;
  position: relative;
  padding-right: $circle-offset;
  padding-top: $outline-size;
  padding-bottom: $outline-size;
}

.color-set-1 {
  background-color: $dark-blue;
  &.counter {
    background-color: $light-blue;
    &:hover {
      //background-color: $neutral_5;
    }
  }
}

.color-set-2 {
  $dark-blue: #c1ecf2;
  background-color: $dark-blue;
  &.counter {
    background-color: $light-blue;
    &:hover {
      //background-color: $neutral_5;
    }
  }
}

.circle {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  //line-height: $size;

  width: $size;
  height: $size;

  border-radius: 50%;
  border: 1px solid white;
  z-index: $z-index-default;
  margin-right: -$circle-offset;

  &:hover {
    z-index: $z-index-hover;
    box-shadow: 0 0 0 $outline-size $neutral_20;
  }

  .letters {
    text-align: center;
    color: $text-color;
    font-size: 0.875rem;
  }

  &.readOnly {
    cursor: default;
  }

  &.active {
    z-index: $z-index-top;
    box-shadow: 0 0 0 $outline-size $blue;
  }

  &.counter {
    z-index: $z-index-bottom;
    .letters {
      color: rgba($text-color, 0.75);
    }
  }
}

.drop-wrapper {
  border: 2px solid $primary;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: $border-radius-default;
  position: absolute;
  z-index: $z-index-dropdown;
  top: $row-height + 0.375rem;
  right: 0;
  width: 185px;
  overflow: hidden;
}

.drop-item {
  padding: ($outline-size + 2 * $row-spacing) 0.5rem;
  fill: $text-lighter;
  display: inline-flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &:hover {
    fill: darken($text-lighter, 20%);
    background-color: darken(white, 3%);
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
  }
}

.drop-btn {
  //margin: 1.25rem;
  margin: 1rem 2rem 1.75rem 2rem;
}

.inner {
  $spacing: 0.125rem;
  $padding: 0.25rem;
  padding: $padding 0;

  max-height: $padding + $row-height * $user-count;
  overflow-y: scroll;

  .drop-item {
    background-color: white;

    &:hover {
      background-color: #c1ecf2;
    }
  }

  .label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 69%;
    word-break: break-word;

    line-height: 1rem;
    font-size: 0.875rem;
    margin-left: $circle-offset + 0.875rem;
  }
}
