.status-circle {
  $size: 1.75rem;
  $opacity: 0.1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: white;
  box-sizing: unset;

  padding: 0.875rem;

  &.no-background {
    background-color: unset !important;
  }

  &.canceled {
    fill: $status-red;
    background-color: rgba($status-red, $opacity);
  }

  &.error {
    fill: $status-red;
    background-color: rgba($status-red, $opacity);
  }

  &.scheduled {
    fill: $status-yellow;
    background-color: rgba($status-yellow, $opacity);
  }

  &.running {
    fill: $status-blue;
    background-color: rgba($status-blue, $opacity);
  }

  &.completed {
    fill: darken($status-green, 9%);
    background-color: rgba($status-green, $opacity);
  }

  svg {
    width: $size;
    height: $size;
  }
}
