@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

$user-properties-width: 330px;
$user-properties-gap: 8px;
$user-properties-margin-bottom: 40px;
$editor-input-label-width: 150px;

.config {
  padding: 32px 20px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    height: 1px;
    top: 0;
    left: 16px;
    right: 16px;
    background-color: $gray-200;
  }

  main {
    section {
      display: flex;
      gap: 12px;

      h3 {
        @include text-body1;
        color: $gray-500;
      }

      &:not(:first-child) {
        margin-top: 32px;
      }

      &:nth-child(1),
      &:nth-child(2) {
        padding-bottom: 24px;
        border-bottom: 1px solid $gray-200;
      }

      .fullWidth {
        width: 100%;
      }
    }
  }
}

.formSection {
  @include text-body2;
  color: $text-color;

  span {
    color: $primary;
  }

  li {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      white-space: nowrap;
    }

    .textInput {
      width: 63px;
      height: 32px;
      margin: 0;

      input {
        max-height: 32px;
      }
    }

    .areaInput {
      margin: 0;
    }

    .wideInput {
      width: 390px;
      margin-bottom: unset;
    }
  }

  li:not(:last-child) {
    margin-bottom: 10px;
  }

  &.devPhase {
    li {
      .userPropertiesLabel {
        min-width: $editor-input-label-width;
      }
    }
  }
}

.formControls {
  margin-top: 16px;
  display: flex;
  padding-right: 22px;
  gap: 12px;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}

.gameStats {
  max-width: 600px;
  margin-bottom: 24px;
}

.editorInputLabel {
  min-width: $editor-input-label-width;
}
