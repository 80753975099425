@import '~crazy-ui/src/scss/common/vars';
@import '~crazy-ui/src/scss/common/typography';

$side-menu-width: 56px;
$side-menu-item-height: 40px;
$side-menu-width-active: 300px;
$side-menu-border-radius: 0 16px 16px 0;
$side-menu-background-color: #33464a;
$nav-top-padding: 10px;
$nav-left-padding: 8px;
$menu-text-color: #829fa4;
$menu-text-lighter: #dfebed;
$glowing-primary: #36dcff;
$logo-width: 40px;
$icon-width: 24px;
$animated-opacity: opacity 100ms ease-in-out;
$animated-opacity-delay: 150ms;

.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;

  background-color: $side-menu-background-color;
  height: 100%;
  width: $side-menu-width;
  z-index: $z-index-fixed;
  padding: $nav-top-padding $nav-left-padding;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: -$side-menu-width-active;
    border-radius: $side-menu-border-radius;
    width: $side-menu-width-active;
    height: 100%;
    background-color: $side-menu-background-color;
    transition: left 350ms ease-in-out;
  }

  &.active {
    &:after {
      left: 0;
    }

    .logo {
      .app {
        transition-delay: $animated-opacity-delay;
        opacity: 1;
      }
    }

    .appVersion {
      transition-delay: $animated-opacity-delay;
      opacity: 1;
    }

    .innerBlock {
      .sectionLabel {
        transition-delay: $animated-opacity-delay;
        opacity: 1;
      }

      .item {
        &.selected {
          &:after {
            transition-delay: 100ms;
            width: $side-menu-width-active - 2 * $nav-left-padding;
          }
        }
        p {
          pointer-events: auto;
          opacity: 1;
          transition-delay: $animated-opacity-delay;
        }
      }
    }

    .bottomBlock {
      .profile {
        .authority {
          .name,
          .role {
            transition-delay: $animated-opacity-delay;
            opacity: 1;
          }
        }

        .logout {
          transition-delay: $animated-opacity-delay;
          opacity: 1;
        }
      }
    }
  }
}

.logo {
  text-decoration: none !important;
  display: flex;
  width: $side-menu-width-active;

  .app {
    transition: $animated-opacity;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: $nav-left-padding;
    opacity: 0;

    .title {
      color: white;
    }

    .subtitle {
      color: $menu-text-color;
      font-size: 0.75rem;
    }
  }

  svg {
    width: $logo-width;
    height: $logo-width;
    text-transform: none;
  }
}

.innerBlock {
  display: flex;
  flex-direction: column;
  padding: $nav-top-padding 0;

  .sectionLabel {
    transition: $animated-opacity;
    opacity: 0;
    color: $glowing-primary;
    text-transform: uppercase;
    padding: 8px 8px;
    font-size: 0.875rem;
  }

  .item {
    @include text-body2;
    position: relative;
    display: flex;
    height: $side-menu-item-height;
    padding-left: 8px;
    align-items: center;
    text-decoration: none;

    &.selected {
      &:after {
        content: '';
        position: absolute;
        width: 40px;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 10px;
        background-color: transparentize($menu-text-color, 0.6);
        z-index: 0;
        transition: width 200ms ease-in-out;
      }
    }

    .icon {
      display: flex;
      margin-right: 0.75rem;
      z-index: 1;
      svg {
        fill: $menu-text-color;
        width: $icon-width;
        height: $icon-width;
      }
    }

    p {
      pointer-events: none;
      transition: $animated-opacity;
      opacity: 0;
      white-space: nowrap;
      color: $menu-text-color;
      line-height: 1.5rem;
      z-index: 1;
    }

    &:hover,
    &.selected {
      .icon {
        svg {
          fill: $glowing-primary;
        }
      }

      p {
        color: white;
      }
    }
  }
}

.appVersion {
  transition: $animated-opacity;
  opacity: 0;

  p {
    line-height: 20px;
    color: $menu-text-color;
    font-size: 0.625rem;
  }
}

.bottomBlock {
  position: fixed;
  bottom: $nav-top-padding;
  width: $side-menu-width-active - 2 * $nav-left-padding;
  transition: width 200ms ease-in-out;
}

.profile {
  @include text-body2;
  position: relative;
  display: flex;
  align-items: center;
  color: white;
  height: 3rem;

  .avatar {
    height: 40px;
    width: 40px;
  }

  .authority {
    display: flex;
    margin-left: 8px;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .name {
      color: $menu-text-lighter;
    }

    .role {
      color: $menu-text-color;
    }

    .name,
    .role {
      transition: $animated-opacity;
      opacity: 0;
    }
  }

  .logout {
    transition: $animated-opacity;
    position: absolute;
    right: 0;
    opacity: 0;

    button {
      color: white;
    }
  }
}
