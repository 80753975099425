.filter-controls {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;

  .input__block {
    min-width: 150px;
    flex-grow: 0;
  }

  &__group {
    display: flex;

    &.evenly {
      justify-content: space-between;
      flex-grow: 1;

      .input__block {
        min-width: 23%;

        &.half {
          min-width: 15%;
        }
      }
    }

    &.start {
      justify-content: flex-end;

      .input__block {
        margin-right: 1rem;

        &:last-child {
          margin-left: unset;
        }
      }
    }

    &.end {
      justify-content: flex-end;

      .input__block {
        margin-left: 1rem;

        &:first-child {
          margin-left: unset;
        }
      }
    }
  }
}
