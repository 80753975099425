@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';

.wrapper {
  grid-area: summary;
  background-color: white;
  margin: auto;
  width: 1280px;
  box-shadow: $shadow-s;
  border-radius: 8px;
}

.info {
  display: grid;
  height: 68px;
  align-items: center;
  grid-template-columns: 200px repeat(5, auto) 32px;
  column-gap: 8px;
  margin: auto;
  padding: 16px 22px 16px 30px;
  @include text-body1;
  font-weight: bold;
  color: $gray-800;

  button {
    padding: unset;

    &:active,
    &:focus-within {
      box-shadow: unset;
    }
    svg {
      $size: 32px;
      height: $size;
      width: $size;
    }
  }
}

.summary {
  padding: 20px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    height: 1px;
    top: 0;
    left: 16px;
    right: 16px;
    background-color: $gray-200;
  }
}

.content {
  section {
    h3 {
      @include text-body1;
      color: $gray-500;
    }

    &:not(:first-child) {
      margin-top: 32px;
    }

    &:not(:last-child) {
      padding-bottom: 24px;
      border-bottom: 1px solid $gray-200;
    }
  }
}

.controls {
  display: flex;
  padding-right: 22px;
  gap: 12px;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}
