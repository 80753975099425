@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: $gray-50;
  border-radius: 0 0 10px 10px;
  padding: 16px 8px;
  margin-bottom: 24px;
}

.tabList {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  padding: 0 16px;

  li:last-child {
    margin-left: auto;
    order: 999;
  }
}
//todo merge with control group styles (?) || define styled components somewhere in components/layout
.tab {
  background: $gray-500;
  color: $gray-200;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 38px;
  padding: 0 12px;
  border-radius: 10px 10px 0 0;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  font-size: 0.75rem;
  font-weight: $weight-medium;
  text-transform: uppercase;

  .tabTitle {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;

    &.masked {
      -webkit-mask-image: linear-gradient(90deg, $gray-1000 0%, $gray-1000 calc(100% - 24px), transparent);
      mask-image: linear-gradient(90deg, $gray-1000 0%, $gray-1000 calc(100% - 24px), transparent);
    }
  }

  button {
    $size: 20px;
    width: $size;
    height: $size;
    padding: 0;
    cursor: pointer;
  }

  svg {
    width: auto;
    fill: $gray-200;
    pointer-events: none;

    &:hover {
      fill: $cyan-900;
    }
  }

  &:hover {
    background: $cyan-400;
  }

  &[aria-selected='true'] {
    background: $cyan-500;

    svg {
      fill: $cyan-700;

      &:hover {
        fill: $cyan-900;
      }
    }
  }
}

.form {
  display: none;
  background: white;
  border-radius: 10px;
  padding: 24px 20px;
  box-shadow: $shadow-xs;

  fieldset {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &.selected {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.label {
  color: $gray-800;
  width: 180px;
}

.intInput {
  max-width: 280px;
}

.inputRow {
  display: flex;
  align-items: center;
  gap: 12px;

  label {
    @include text-body2;
    text-transform: none;
  }
}

.input {
  margin-bottom: unset;

  div {
    width: 60px;
    margin: 0 10px 5px 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'],
  input[type='text'] {
    -moz-appearance: textfield;
  }
}

.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  button {
    $size: 20px;
    width: $size;
    height: $size;
    cursor: pointer;
    padding: 0;

    svg {
      width: auto;
      fill: $text-color;

      &:hover {
        fill: $secondary;
      }
    }
  }
}
