@import '~crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/colors';
@import '~crazy-ui-next/src/scss/common/shadows';

$radius: 10px;

.tableTitle {
  @include text-body1;
  font-weight: bold;
  color: $gray-800;
  margin-bottom: 14px;
}

.tableWrapper {
  margin-right: 16px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-500;
  }
}

.table {
  @include text-body2;
  box-shadow: $shadow-xs;
  border-radius: $radius;
  margin-bottom: 24px;
  color: $gray-800;

  thead {
    @include text-caption;

    th {
      background: $gray-100;

      &:first-child {
        border-top-left-radius: $radius;
        min-width: 86px;
      }
      &:last-child {
        border-top-right-radius: $radius;
      }
    }
  }

  tbody {
    .inactiveGroup {
      color: $gray-500;
    }

    tr {
      height: 66px;

      td {
        &.emptyValue {
          color: $gray-500;
        }
      }
    }

    tr:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: $radius;
        }
        &:last-child {
          border-bottom-right-radius: $radius;
        }
      }
    }
  }

  th,
  td {
    padding: 1rem;
    border: 1px solid $gray-300;

    &[data-wider='true'] {
      min-width: 200px;
    }
  }

  p {
    text-align: center;
  }

  .groupNameCell {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.modal {
  color: $text-color;

  p {
    span {
      color: $primary;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    p {
      @include text-h3-medium;
      vertical-align: middle;
      color: $gray-800;
    }
  }

  .controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    gap: 16px;
  }
}
