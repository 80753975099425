@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

$size: 32px;
$circle-offset: 14px;

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &[data-count] {
    min-width: 115px;
  }

  .circle {
    margin-right: -$circle-offset;

    &:last-child {
      margin-right: unset;
    }
  }
}

.circle {
  display: inline-flex;
  min-width: $size;
  height: $size;
  overflow: hidden;
  border-radius: 50%;
  background-color: $gray-300;
  outline: 1px solid white;

  picture + p {
    display: none;
  }

  img {
    height: $size;
    width: $size;
    object-fit: cover;
  }

  p {
    @include text-body2-medium;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
}
