@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';

.wrapper {
  background-color: white;
  display: flex;
  justify-content: center;
  box-shadow: $shadow-s;
  margin-bottom: 12px;
}

.header {
  height: 108px;
  width: 1280px;
  padding: 20px 0 16px;
  display: grid;
  grid-template-columns: 104px 1fr auto;
  grid-template-rows: 1fr 1fr;
  gap: 0 16px;
  grid-auto-flow: row;
  grid-template-areas:
    'icon game-name actions'
    'icon exp-name actions';
  align-items: center;
}

.icon {
  grid-area: icon;
  $size: 72px;
  height: $size;
  width: $size;
  margin-left: 20px;
  border-radius: 15px;
}

.actions {
  height: 42px;
  grid-area: actions;
  display: flex;
  align-items: flex-end;

  ul {
    & > li:first-child {
      display: none;
    }
  }
}

.expName {
  grid-area: exp-name;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  @include text-h4;
  color: $gray-900;
}

.gameName {
  grid-area: game-name;
  display: grid;
  align-items: center;
  @include text-h3-medium;
  color: $gray-900;
}
