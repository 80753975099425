@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.wrapper {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    $size: 48px;
    height: $size;
    width: $size;
    fill: $gray-300;
  }

  span {
    @include text-body-caps;
    color: $gray-800;
  }
}
