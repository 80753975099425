@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';
@import 'node_modules/crazy-ui-next/src/scss/common/shadows';
@import 'node_modules/crazy-ui-next/src/scss/common/vars_old';

$drop-width: 280px; // 275px with labels

.dropdown {
  position: relative;
  display: flex;
}

.listWrapper {
  position: absolute;
  z-index: $z-index-dropdown;
  top: 48px;
  right: 0;
  width: $drop-width;
  background-color: white;
  border: 1px solid $gray-300;
  padding: 12px 4px;
  border-radius: 10px;
  box-shadow: $shadow-s;
}
.list {
  max-height: 400px;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: $gray-500;
  }
}

.option {
  @include text-body1;
  display: flex;
  align-items: center;
  border-radius: 8px;
  color: $gray-800;
  height: 36px;
  padding: 0 12px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: $gray-100;
  }
}

$model-color: (
  'int': #1e88e5,
  'float': #43a047,
  'percent': #ec407a,
  'text': #607d8b,
  'chart': #607d8b,
  'bool': #5e35b1
);

.dataType {
  display: none;
  @include text-caption;
  padding: 2px 6px;
  text-transform: uppercase;
  border-radius: 6px;
  font-weight: 600;

  @each $key, $color in $model-color {
    &[data-model='#{$key}'] {
      color: $color;
      background-color: rgba($color, 0.15);
    }
  }
}
