@import '~crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/colors';
@import '~crazy-ui-next/src/scss/common/shadows';

// todo [Tech-debt] [CU-1642] clean up unnecessary styles

$section-gap: 16px;
$controls-gap: 12px;
$user-properties-width: 330px;
$editor-input-label-width: 120px;
$user-properties-gap: 8px;
$user-properties-margin-bottom: 40px;

.section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content 1fr;
  gap: 16px 16px;
  grid-template-areas:
    'summary summary'
    'winner sanity'
    'result result';
  max-width: 1280px;
  margin: auto;
}

.summaryListWrapper {
  display: flex;
  flex-direction: column;
}

.summaryList,
.editorList {
  @include text-body2;
  color: $text-color;

  li {
    display: flex;
    gap: 8px;
  }

  li:not(:last-child) {
    margin-bottom: 16px;
  }

  span {
    color: $primary;
  }
}

.editorList {
  li {
    align-items: center;
    white-space: nowrap;

    .editorInputLabel {
      min-width: $editor-input-label-width;
    }

    .editorInput {
      width: 63px;
      margin: 0;
    }
  }

  .multiInput {
    div:not(:last-child) {
      margin-right: 8px;

      input {
        min-width: unset;
      }
    }
  }
}

.objectiveSelect {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;

  button {
    height: 28px;
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &.unselected {
      background-color: $gray-100;
      border-color: $gray-100;
      color: $gray-600;
    }
  }
}

.summaryPercentWrapper {
  span {
    margin-left: 8px;
  }

  input {
    margin: 12px 0 0 0;
  }
}

.hint {
  $size: 24px;
  height: $size;
  width: $size;
  cursor: pointer;
}

.formControls {
  display: flex;
  gap: 12px;

  margin-left: auto;
  margin-top: auto;
}
