.user-config {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .input__text.error {
    color: $alert-red;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__column-head {
    @include note-text;
    font-weight: 800;
    margin-bottom: 0.5rem;
  }

  &__item {
    display: flex;
    align-items: baseline;
    position: relative;

    .minus {
      $size: 0.5rem;
      right: -2.25rem;
      top: 0.3rem;

      position: absolute;
      padding: 0.5rem;
      svg {
        transform: scale(1.75);
        width: $size;
        height: $size;
      }
    }

    span {
      margin: 0 0.25rem;
      color: $text-lighter;
      font-weight: bold;
    }

    .key {
      flex-basis: 40%;
    }
    .value {
      flex-grow: 1;
    }
  }

  &__controls {
    .c-btn {
      width: 100%;
      line-height: 1.5rem;
      padding: 0.4rem 0.8rem;
    }
  }

  .c-field input {
    padding: 0.4rem 0.8rem;
  }
}
