@import 'node_modules/crazy-ui-next/src/scss/common/typography';
@import 'node_modules/crazy-ui-next/src/scss/common/colors';

.config {
  padding: 32px 30px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    height: 1px;
    top: 0;
    left: 16px;
    right: 16px;
    background-color: $gray-200;
  }

  main {
    section {
      h3 {
        @include text-body1;
        color: $gray-500;
      }

      &:not(:first-child) {
        margin-top: 32px;
      }

      &:not(:last-child) {
        padding-bottom: 24px;
        border-bottom: 1px solid $gray-200;
      }
    }
  }
}

.configSection {
  $title-col: 210px;
  $controls-col: 200px;

  display: grid;
  grid-template-columns: $title-col 1fr $controls-col;
  gap: 8px;
}
