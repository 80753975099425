@import '~crazy-ui-next/src/scss/common/colors';
@import '~crazy-ui-next/src/scss/common/typography';

.wrapper {
  &.error {
    h2,
    h3 {
      color: $alert-error;
    }
  }

  &.warn {
    h2,
    h3 {
      color: $alert-warn;
    }
  }

  &.generic {
    h2,
    h3 {
      color: $primary;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  h2 {
    @include text-h1-medium;
    margin-top: 3rem;
    font-size: 5rem;
    line-height: 6rem;
    color: $alert-error;
    text-align: center;
  }

  h3 {
    @include text-h3;
    font-size: 2rem;
    line-height: 2.5rem;
    color: $alert-error;
    text-align: center;
    margin-bottom: 2rem;
  }

  > div > p {
    @include text-h4;
    text-align: center;
    color: $text-color-5;
  }

  .actionBlock {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      @include text-h4-medium;
      margin: 1.5rem 0;
      justify-content: center;
      text-align: center;
      color: $text-color-5;
    }

    a,
    button {
      min-width: 250px;
    }
  }
}
