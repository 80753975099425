@import '~crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/colors';

$purple-100: #edebff;
$purple-600: #786cd9;

.status {
  @include text-body-caps;
  display: inline-flex;
  justify-content: center;
  color: $text-color;
  border-radius: 10px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  padding: 0 16px;

  &[data-status='CANCELED'] {
    background-color: $gray-100;
    color: $gray-600;
  }

  &[data-status='ERROR'] {
    background-color: $magenta-100;
    color: $magenta-600;
  }

  &[data-status='STOPPED'] {
    background-color: $purple-100;
    color: $purple-600;
  }

  &[data-status='SCHEDULED'] {
    background-color: $yellow-100;
    color: $yellow-600;
  }

  &[data-status='SCHEDULED_IN_DEV'] {
    background-color: $yellow-100;
    color: $yellow-600;
  }

  &[data-status='IN_DEV'] {
    background-color: $cyan-100;
    color: $cyan-600;
  }

  &[data-status='RUNNING'] {
    background-color: $green-100;
    color: $green-600;
  }
}
