@import '~crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/colors';
@import '~crazy-ui-next/src/scss/common/shadows';

.range {
  -webkit-appearance: none;
  width: 350px;
  height: 7px;
  border-radius: 5px;
  background-color: $gray-300;
  background-image: linear-gradient($cyan-500, $cyan-500);
  //default value. Controlled via js
  background-size: 0 100%;
  background-repeat: no-repeat;

  &:disabled {
    background-image: linear-gradient($gray-300, $gray-300);
    pointer-events: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 32px;
    width: 40px;
    background: url('../../../assets/svg/range-thumb.svg');
    cursor: ew-resize;
    transition: background 200ms ease-in-out;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    height: 32px;
    width: 40px;
    background: url('../../../assets/svg/range-thumb.svg');
    cursor: ew-resize;
    transition: background 200ms ease-in-out;
  }

  &::-ms-thumb {
    -webkit-appearance: none;
    height: 32px;
    width: 40px;
    background: url('../../../assets/svg/range-thumb.svg');
    cursor: ew-resize;
    transition: background 200ms ease-in-out;
  }
}
