.login {
  display: flex;
  flex-direction: column;

  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    .icon {
      $size: 5rem;
      $padding: 1rem;
      width: $size;
      height: $size;
      background-color: $primary;
      border-radius: 50%;
      margin-bottom: 1rem;
      padding: $padding;

      svg {
        width: $size - $padding * 2;
        height: $size - $padding * 2;
        fill: white;
      }
    }

    .text {
      font-size: 2rem;
      line-height: 2.5rem;
      text-align: center;
    }
  }

  &__body {
    display: flex;
    margin-bottom: 3rem;
  }

  .form {
    &__body-column {
      width: 100%;
      padding: 0 25%;
    }

    &__controls {
      margin-top: 1rem;
    }
  }
}
