@import 'node_modules/crazy-ui/src/scss/common/vars';
@import 'node_modules/crazy-ui/src/scss/common/typography';

$radius: 10px;
$shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
$highlight-color: #fd5b87;
$highlight-color-light: #ffd4df;
$background-color: #f0f9fa;
$header-text: #829598;
$day-text: #96aeb4;
$background-main: white;

@mixin highlight-circle {
  content: '';
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  border-radius: 100%;
  right: 0.125rem;
  z-index: -1;
  height: 1.875rem;
  width: 1.875rem;
  background: $highlight-color;
}

@mixin square-range-background {
  content: '';
  position: absolute;
  width: 50%;
  top: 50%;
  right: 0;
  z-index: -2;
  height: 100%;
  background: $highlight-color-light;
}

.calendarCustom {
  box-shadow: $shadow;
  border-radius: $radius;
  border-color: $neutral_20;

  :global {
    .react-datepicker {
      &__week,
      &__day-names {
        margin-bottom: 8px;
      }

      &__day-name,
      &__day,
      &__time-name {
        margin: 0;
        padding: 4px;
        box-sizing: content-box;
        height: 22px;
        width: 26px;
        line-height: 22px;
      }

      &__month-container {
        background-color: $background-color;
        border-radius: $radius;
        margin: 8px;
      }

      &__time-container {
        background-color: $background-color;
        border-radius: $radius;
        margin: 8px;
        border: none;
      }

      &__header {
        padding: 0;
        background-color: $background-color;
        border-bottom: unset;
        border-radius: $radius;
      }

      &__navigation {
        height: 44px;
        top: 10px;

        &--previous {
          left: 24px;
        }

        &--next {
          right: 24px;

          &--with-time:not(&--with-today-button) {
            right: 140px;
          }
        }
      }

      &__navigation-icon {
        &:before {
          border-color: lighten($header-text, 20%);
        }

        &:hover:before {
          border-color: $header-text;
        }
      }

      &__current-month {
        color: $header-text;
        height: 44px;
        line-height: 44px;
      }

      &__day-name {
        color: $day-text;
        font-weight: $text-bold;
      }

      &__month {
        margin: 0 0 10px 0;
      }

      &__time {
        background: unset;

        &-list-item:hover {
          color: white;
          background-color: $highlight-color !important;
        }
      }

      &__day {
        outline: none;
        border-radius: initial;
        z-index: $z-index-modal;
        position: relative;

        &--in-range:not(&--in-selecting-range) {
          background: none;
        }

        &--in-selecting-range {
          background: none;
          color: $text-color;
        }

        &--in-range {
          background-color: $highlight-color-light !important;
          color: $text-color;
        }

        &--range-start,
        &--range-end {
          background: none !important;
          color: white;
          font-weight: $text-bold;
        }

        &--range-start:before {
          @include highlight-circle;
          left: 0.125rem;
        }

        &--range-end:before {
          @include highlight-circle;
        }

        &--range-start:after {
          transform: translate(0, -50%);
          @include square-range-background;
        }

        &--range-end:after {
          transform: translate(-100%, -50%);
          @include square-range-background;
        }

        &--selected {
          background: none;
          font-weight: $text-bold;
          &:before {
            @include highlight-circle;
          }
        }

        &--keyboard-selected {
          background: none;
          color: $text-color;
        }

        &:hover {
          color: white;
          font-weight: $text-bold;
          &:before {
            @include highlight-circle;
          }
        }
      }

      &__today-button {
        background: unset;
        border-radius: 0.75rem;
        border: 1px solid $primary;
        color: $primary;
        height: 2rem;
        font-weight: $text-medium;
        margin: 0.625rem auto;
        width: 12rem;

        &:hover {
          background-color: $primary;
          color: white;
        }
      }

      &__triangle:after {
        border-bottom-color: $background-main !important;
      }

      &-time {
        &__header {
          color: $header-text;
        }
      }
    }

    .react-datepicker__day--range-start.react-datepicker__day--range-end:after {
      display: none;
    }

    .react-datepicker__day--in-range.react-datepicker__day--keyboard-selected {
      background: $highlight-color-light;
    }
  }
}
