.experiment-preview {
  display: flex;
  flex-direction: column;

  .interval__types {
    justify-content: center;
  }

  .interval__item {
    width: 30%;
    max-width: 30%;
  }

  &__controls {
    display: flex;
    align-items: center;
    align-content: center;

    .c-btn {
      min-width: 120px;
      margin-left: 1rem;

      svg {
        transform: scale(1.125);
      }

      &:first-child {
        margin-left: unset;
      }
    }
  }

  &__header {
    display: flex;
    margin-bottom: 1rem;

    .text-group {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;

      padding: 0.25rem 0;
      //align-items: center;
    }

    .text-label {
      @include note-text;
      font-size: 1rem;
      line-height: 1rem;

      fill: $text-lighter;

      position: relative;

      display: inline-flex;

      align-items: baseline;

      svg {
        display: inline-flex;
        align-self: center;

        margin-right: 0.5rem;
        height: 1rem;
        width: 1rem;
      }
    }

    .text-value {
      line-height: 2rem;
      font-size: 1.125rem;
      color: $text-color;
    }

    .text-block {
      flex-basis: 12.5%;
      //min-width: 80px;
      //margin: 0 0.5rem;
      margin-left: 0.75rem;

      &.item-psdk {
        flex-grow: 1;
        flex-basis: 20%;

        .text-value {
          color: $primary;
          font-weight: bold;
        }
      }

      &.item-firebase {
        flex-grow: 1;
        flex-basis: 20%;
      }

      &.item-name {
        flex-grow: 1;
      }

      &.item-id {
        flex-basis: 5%;
        min-width: 50px;
        margin-left: 1rem;

        .text-value {
          color: $color-indigo;
        }
      }

      &.item-date {
        flex-basis: 10%;
      }

      &.item-period {
        flex-basis: 12%;
        min-width: 70px;
      }
    }
  }

  .intervals {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    .dot {
      display: inline-flex;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      margin-right: 0.5rem;
      background-color: $text-lighter;

      @mixin makeColor($color) {
        border-color: $color;
        background-color: $color;
        color: darken($color, 15%);
      }

      @include makeColor($text-lighter);

      &.blue {
        @include makeColor($color-blue);
      }

      &.green {
        @include makeColor($color-lime);
      }

      &.indigo {
        @include makeColor($color-indigo);
      }

      &.number {
        $size: 1.75rem;
        align-items: center;
        justify-content: center;
        font-size: 0.95rem;
        width: $size;
        height: $size;
        line-height: $size;
        background-color: unset;
        border-width: 4px;
        border-style: solid;
        font-weight: bold;
        //color: rgba(black, 0.5);
      }
    }

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 1rem;
      margin-bottom: 1rem;

      .title {
        @include note-text;
        font-weight: 800;
      }

      .legend {
        display: flex;

        &__item {
          margin-left: 1rem;
          //font-weight: bold;
        }
      }
    }

    &__list {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &.collapsed:after {
        content: '';
        position: absolute;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0) 100%
        );
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    &__item {
      margin-right: 1rem;
      margin-bottom: 1rem;

      padding: 0.5rem;
      border: 2px solid $neutral_10;
      //border: 2px solid #f0f0f0;
      //background-color: lighten($primary, 57%);
      border-radius: 6px;
      display: flex;
      flex-direction: row;

      .dot:last-child {
        margin-right: unset;
      }
    }

    &__controls {
      display: flex;
      justify-content: center;

      .c-btn {
        min-width: 200px;
      }
    }
  }

  .analytics-query {
    margin-top: 1rem;

    textarea {
      min-height: 600px;
      max-height: 90vh;
    }
  }
}
